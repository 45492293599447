<template>
  <v-app>
    <div class="settingsPage">
      <div class="container">
        <h1>Settings</h1>
        <br />
        <br />
        <v-card
          class="mx-auto"
          max-width="800"
        >
          <div style="padding-right: 20px; padding-left: 20px">        
            <v-list
              subheader
              three-line
              v-if="loggedIn"
            >
              <v-subheader>User Profile</v-subheader>
              <v-list-tile>
                
                <v-list-tile-content>
                  <v-list-tile-title>Nickname</v-list-tile-title>
                  <v-list-tile-sub-title>Set your display name appears next to your comments</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <div class="inputContainer">
                <v-text-field
                  v-bind:key="'nickname_key'"
                  ref="nickname_ref"
                  :rules="textRules"
                  label="enter nickname"
                  clearable
                  type="text"
                  @change="processNicknameChange"
                  v-model="nickname">
                </v-text-field>
              </div>
              <br />
              <br />
              <v-divider></v-divider>
              <br />

            </v-list>
            

            
            

            <v-list
              subheader
              two-line
              v-if="loggedIn"
            >
              
              <v-subheader>Communication</v-subheader>


              <v-list-tile>
                
                <v-list-tile-content>
                  <v-list-tile-title>Public Comments</v-list-tile-title>
                  <v-list-tile-sub-title>Allow your comments to be seen by others.  Change affects new comments.</v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-switch v-model="allowPublicComments" @change="processPublicCommentSetting"></v-switch>
                </v-list-tile-action>
              </v-list-tile>

              <v-list-tile>
                
                <v-list-tile-content>
                  <v-list-tile-title>Notifications</v-list-tile-title>
                  <v-list-tile-sub-title>Allow web push notifications:</v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-switch v-model="allowNotifications" @change="theNotificationToggleChanged"></v-switch>
                </v-list-tile-action>
              </v-list-tile>
              <div class="inputContainer" v-if="allowNotifications">
                <table width="100%">
                  <tr v-if="!disableButton">
                    <td width="50%" align="left" style="padding-left: 8px;" >
                      <span style="font-size: .75em;"><i>{{ browserInfo }}</i>, 
                      Permission:&nbsp;&nbsp;<b>{{ permission }}</b>, 
                      Credentials:&nbsp;&nbsp;<b><span :style="credColor"> {{ isValid }}</span></b></span><br>
                    </td>
                    
                  </tr>
                  <tr v-if="!disableButton">
                    <td width="50%" align="left">
                      <v-btn
                        :style="buttonColor"
                        :disabled="disableButton"
                        @click="refreshNotifications"
                        >
                        Refresh Notifications
                      </v-btn>

                    </td>
                  </tr>
                </table>
                
              </div>
              <br />
              <br />
              <v-divider></v-divider>
              <br>
            </v-list>
            

            <v-list
              subheader
              two-line
            >
              
              <v-subheader>Cookie Preferences</v-subheader>

              <!--
              <v-list-tile>
                <v-list-tile-action>
                  <v-switch v-model="allowAllCookies" @change="processAllCookiesSetting"></v-switch>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-title>All Cookies</v-list-tile-title>
                  <v-list-tile-sub-title>Allow use of all cookie types listed below</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              -->

              <v-list-tile>
                
                <v-list-tile-content>
                  <v-list-tile-title>Essential cookies</v-list-tile-title>
                  <v-list-tile-sub-title>These cookies are strictly necessary to provide you with the services available through our websites.</v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-switch v-model="allowEssentialCookies" disabled></v-switch>
                </v-list-tile-action>
              </v-list-tile>

              <v-list-tile>
                
                <v-list-tile-content>
                  <v-list-tile-title>Performance cookies</v-list-tile-title>
                  <v-list-tile-sub-title>Enables enhanced site performance and functionality of our website, but are non-essential to its use. </v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-switch v-model="allowPerformanceCookies" ></v-switch>
                </v-list-tile-action>
              </v-list-tile>

              <v-list-tile>
                
                <v-list-tile-content>
                  <v-list-tile-title>Analytic & customization cookies</v-list-tile-title>
                  <v-list-tile-sub-title>Enables data aggregation to help us understand how heedi is being used, or to customize our site for you.</v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-switch v-model="allowAnalyticCookies" ></v-switch>
                </v-list-tile-action>
              </v-list-tile>

              <v-list-tile>
                
                <v-list-tile-content>
                  <v-list-tile-title>Advertising cookies</v-list-tile-title>
                  <v-list-tile-sub-title>Enables relevent advertising messages and prevents reappearing ads.  Allows ad delivery based on your interests.</v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-switch v-model="allowAdvertisingCookies" ></v-switch>
                </v-list-tile-action>
              </v-list-tile>

              <v-list-tile>
                
                <v-list-tile-content>
                  <v-list-tile-title>Social networking cookies</v-list-tile-title>
                  <v-list-tile-sub-title>Enables you to share heedi content across social networking websites; may also be used for advertising purposes.</v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-switch v-model="allowSocialCookies" ></v-switch>
                </v-list-tile-action>
              </v-list-tile>


              
              <br />
              <v-divider></v-divider>
              <br />

            </v-list>


            <v-list
              subheader
              three-line
              v-if="loggedIn"
            >
              <v-subheader>Cooking Settings</v-subheader>
              <v-list-tile>
                
                <v-list-tile-content>
                  <v-list-tile-title>Current Cooking Menu</v-list-tile-title>
                  <v-list-tile-sub-title>Set your cooking menu for the next few weeks</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <div class="inputContainer">

                <v-select
                  :rules="[(v) => !!v || 'Selection is required']"
                  :ref="'cookingMenu'"  
                  :items="['Menu 1']"
                  label="Select menu"
                  @change="processCurrentMenuChange"
                  v-model="currentMenu">
                </v-select>


              </div>
              <br />



              <v-list-tile>
                <v-list-tile-content>
                  <v-list-tile-title>Cooking Days</v-list-tile-title>
                  <v-list-tile-sub-title>Set your cooking days</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <div class="inputContainer">
                
                <table>
                  <tr>
                    <td width="100%">
                      <div class="addPeriods_periodDayOfWeek">
                        <v-checkbox
                          :ref="`cookingDays_Monday`"
                          :v-bind:key="'Monday_key'"
                          :label="'Monday'"
                          value="Monday"
                          @change="processCookingDayChange"
                          v-model="cookingDays">
                        </v-checkbox>
                      </div>
                      <div class="addPeriods_periodDayOfWeek">
                        <v-checkbox
                          :ref="`cookingDays_Tuesday`"
                          :v-bind:key="'Tuesday_key'"
                          :label="'Tuesday'"
                          value="Tuesday"
                          @change="processCookingDayChange"
                          v-model="cookingDays">
                        </v-checkbox>
                      </div>
                      <div class="addPeriods_periodDayOfWeek">
                        <v-checkbox
                          :ref="`cookingDays_Wed`"
                          :v-bind:key="'Wed_key'"
                          :label="'Wednesday'"
                          value="Wednesday"
                          @change="processCookingDayChange"
                          v-model="cookingDays">
                        </v-checkbox>
                      </div>
                      <div class="addPeriods_periodDayOfWeek">
                        <v-checkbox
                          :ref="`cookingDays_Thu`"
                          :v-bind:key="'Thu_key'"
                          :label="'Thursday'"
                          value="Thursday"
                          @change="processCookingDayChange"
                          v-model="cookingDays">
                        </v-checkbox>
                      </div>
                      <div class="addPeriods_periodDayOfWeek">
                        <v-checkbox
                          :ref="`cookingDays_Fri`"
                          :v-bind:key="'Fri_key'"
                          :label="'Friday'"
                          value="Friday"
                          @change="processCookingDayChange"
                          v-model="cookingDays">
                        </v-checkbox>
                      </div>
                      <div class="addPeriods_periodDayOfWeek">
                        <v-checkbox
                          :ref="`cookingDays_Sat`"
                          :v-bind:key="'Sat_key'"
                          :label="'Saturday'"
                          value="Saturday"
                          @change="processCookingDayChange"
                          v-model="cookingDays">
                        </v-checkbox>
                      </div>
                      <div class="addPeriods_periodDayOfWeek">
                        <v-checkbox
                          :ref="`cookingDays_Sun`"
                          :v-bind:key="'Sun_key'"
                          :label="'Sunday'"
                          value="Sunday"
                          @change="processCookingDayChange"
                          v-model="cookingDays">
                        </v-checkbox>
                      </div>
                    </td>
                  </tr>
                </table>


              </div>

              <br />
              <br />
              <v-divider></v-divider>
              <br />

            </v-list>
            <br>
            <br>


            <br>
            <br>


          </div>
        </v-card>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <VueFooter :dark-text="true" />


    </div>
  </v-app>
</template>

<script>
  import '@/plugins/vuetify'
  import { mapActions, mapGetters } from 'vuex'
  import settingsModule from '@/store/settings'
  import signinModule from '@/store/signin'
  import userModule from '@/store/user'
  import VueFooter from '@/components/VueFooter'
  //import SixArticles from '@/components/SixArticles'
  //import feedbackModule from '@/store/feedback'
  //import experienceModule from '@/store/experience'
  //import LocaleSwitcher from '@/components/LocaleSwitcher'
  //import NotificationToggle from '@/components/NotificationToggle'

  export default {
    name: "settings",
    components: {
      VueFooter,
      //SixArticles,
      //NotificationToggle
    },
    data () {
      return {
        timePeriodItems: [
          "morning", "afternoon", "evening", "late night"
        ],
        textRules: [
          (v) => !!v || 'Input is required',
          (v) => (v && v.length <= 100) || 'Max 100 characters'
        ],

        
      }
    },
    computed: {
     ...mapGetters('settings', [
      'allowNotifications',
      'notificationSubscriptions'
      
     ]),
    ...mapGetters('auth', {
      profile: 'profile',
      loggedIn: 'loggedIn',
    }),
    /*
    notificationToggle: {
      set: function (value) {
        this.$store.commit('settings/SET_NOTIFICATION_TOGGLE', { notificationToggle: value }, { root: true })
      },
      get: function () {
        return this.$store.state.settings.notificationToggle
      }
    },*/
    allowNotifications: {
      set: function (value) {
        this.$store.commit('settings/SET_ALLOW_NOTIFICATIONS', { allowNotifications: value }, { root: true })
      },
      get: function () {
        return this.$store.state.settings.allowNotifications
      }
    },
    nickname: {
      set: function (value) {
        this.$store.commit('settings/SET_NICKNAME', { nickname: value }, { root: true })
      },
      get: function () {
        return this.$store.state.settings.nickname
      }
    },
    allowPublicComments: {
      set: function (value) {
        this.$store.commit('settings/SET_PUBLIC_COMMENTS', { allowPublicComments: value }, { root: true })
      },
      get: function () {
        return this.$store.state.settings.allowPublicComments
      }
    },

    /* auth module */
    allowAllCookies: {
      set: function (value) {
        this.$store.commit('auth/SET_ALL_COOKIES', { allowAllCookies: value }, { root: true })
      },
      get: function () {
        return this.$store.state.auth.allowAllCookies
      }
    },
    allowEssentialCookies: {
      set: function (value) {
        this.$store.commit('auth/SET_ESSENTIAL_COOKIES', { allowEssentialCookies: value }, { root: true })
      },
      get: function () {
        return this.$store.state.auth.allowEssentialCookies
      }
    },
    allowPerformanceCookies: {
      set: function (value) {
        this.$store.commit('auth/SET_PERFORMANCE_COOKIES', { allowPerformanceCookies: value }, { root: true })
      },
      get: function () {
        return this.$store.state.auth.allowPerformanceCookies
      }
    },
    allowAnalyticCookies: {
      set: function (value) {
        this.$store.commit('auth/SET_ANALYTIC_COOKIES', { allowAnalyticCookies: value }, { root: true })
      },
      get: function () {
        return this.$store.state.auth.allowAnalyticCookies
      }
    },
    allowAdvertisingCookies: {
      set: function (value) {
        this.$store.commit('auth/SET_ADVERTISING_COOKIES', { allowAdvertisingCookies: value }, { root: true })
      },
      get: function () {
        return this.$store.state.auth.allowAdvertisingCookies
      }
    },
    allowSocialCookies: {
      set: function (value) {
        this.$store.commit('auth/SET_SOCIAL_COOKIES', { allowSocialCookies: value }, { root: true })
      },
      get: function () {
        return this.$store.state.auth.allowSocialCookies
      }
    },
    currentMenu: {
      set: function (value) {
        this.$store.commit('settings/SET_CURRENT_MENU', value, {root: true})
      },
      get: function () {
        return this.$store.state.settings.currentMenu
      }
    },
    cookingDays: {
      set: function (value) {
        this.$store.commit('settings/SET_COOKING_DAYS', value, {root: true})
      },
      get: function () {
        return this.$store.state.settings.cookingDays
      }
    },

    browserInfo () {
      //localStorage.setItem('browserInfo', JSON.stringify({'name': browser.name, 'os': browser.os }))
      var info = JSON.parse(localStorage.getItem("browserInfo"))
      var str = info.name+"-"+info.os
      return str
    },
    permission () {
      var permission = Notification.permission 
      return permission
    },
    isValid () {
      if(this.notificationSubscriptions) {
        if(this.notificationSubscriptions[this.browserInfo]) {
          if(this.notificationSubscriptions[this.browserInfo].isValid) {
            return "Valid"
          }else{
            return "Invalid"
          }
        }else{
          return "Invalid"
        }
      }else{
        return "Invalid"
      }
    },
    credColor () {
      if(this.isValid === 'Invalid') {
        return "color:red;"
      }else if(this.isValid === 'Valid') {
        return "color:green;"
      }else{
        return "color:grey;"
      }
    },
    buttonColor () {
      if((this.isValid === 'Invalid') || (this.permission === 'denied')) {
        return "background-color:orange;"
      }else {
        return "background-color:lightgrey;"
      }
    },
    disableButton () {
      if((this.isValid === 'Valid') && (this.permission === 'granted')) {
        return true
      }else{
        return false
      }
    }
   
  },
  methods: {
    ...mapActions('settings', [
      'changeNotification',
      'changeNickname',
      'changePublicCommentSetting',
      'changeMenuCookingSetting',
      'changeCookingDaysSetting',
      'refreshNotifications'
    ]),
    ...mapActions('signin', [
        'autoSignIn'
    ]),
    ...mapActions('user', [
        'getUserState'
    ]),
    ...mapActions('search', [
        'setShowSearch'
      ]),
    ...mapActions('auth', [
        'syncCookieState'
      ]),
    theNotificationToggleChanged (value) {
      this.changeNotification(value)
    },
    refreshSubscription() {
      //todo
    },

    processNicknameChange (value) {
      this.changeNickname(value)
    },

    processPublicCommentSetting (value) {
      this.changePublicCommentSetting(value)
    },

    processCurrentMenuChange (value) {
      this.changeMenuCookingSetting(value)
    },
    processCookingDayChange () {
      this.changeCookingDaysSetting(this.cookingDays)
    },
    
    changeLang(lang) {
      window.location = lang;
      location.reload();
    }
  },
  beforeCreate () {
    //MUST HAVE ALL THREE OF THESE MODULES LOADED FOR AUTOSIGNIN TO WORK
      if(!this.$store.state.settings) this.$store.registerModule('settings', settingsModule)
      if(!this.$store.state.signin) this.$store.registerModule('signin', signinModule)
      if(!this.$store.state.user) this.$store.registerModule('user', userModule)
      
  },
  created () {
    /* eslint-disable no-undef */
    gtag('config', 'G-H9RD8W4MLR', {'page_path': '/settings'});
    this.autoSignIn()
    this.syncCookieState()

      
  },
  mounted () {
    this.setShowSearch(false)

  },
  updated () {

  },
  watch: {

  }
}
</script>



<style scoped>

.settingsPage {
  background-color: transparent;
}

.inputContainer {
  width: 70%;
  margin: 0 auto;
}

.addPeriods_periodDayOfWeek {
  background-color: transparent;
  padding: 2px;
  display: inline-block;
}

</style>
